import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    disclaimer: {
        backgroundColor: "#e1f6fe",
    }
});

const statement1 = `Do not dirve or operate heavy or complex machinery while using this software.`
const statement2 = `If you suffer from epilepsy or irregular heartbeat, consult your  physician prior to using this software.`
const statement3 = `In the unlikely event that you experience any unusual physical or mental discomfort, immediately discontinue use.`
const statement4 = 'This software is offered \'as is\' and is intended solely for entertainment purposes.'
const statement5 = 'No therapeutic results are claimed or guranteed by the use of this software.'
const statement6 = `We accept no responsability or liability for other sites reached via links on Binaural Bee.`
const copyright = 'Software ©Luke Rodies September 2020'

export default function Disclaimer(props) {
    const classes = useStyles();
    return (
        <Typography component="div" className={classes.disclaimer}>
            <ul>
                <li>{statement1}</li>
                <li>{statement2}</li>
                <li>{statement3}</li>
                <li>{statement4}</li>
                <li>{statement5}</li>
                <li>{statement6}</li>
                <li>{copyright}</li>
            </ul>
        </Typography >)
}




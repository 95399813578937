import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    divLandscape: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "center",
        marginBottom: "3vh",
        width: "90%"
    },
    div2Landscape: {
        display: "inline",
        width: "10%",
        alignItems: "top"
    },
    divPortrait: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    divMoreLessProtrait: {
        height: "10vh",
    },
    divMoreLessLandscape: {
        width: "10vw",
        backgroundColor: "yellow"
    },
    sliderDivLandscape: {
        width: "70%",
        marginLeft: "1vw"
    },
    sliderDivPortrait: {
        height: "54vh",
    },
    inputLandscape: {
        marginLeft: "2vw",
        marginRight: "1vw"
    },
    inputPortrait: {
        width: 42,
        marginTop: "3vh"
    },
    labelDivH: {
        width: "8vw",
        marginRight: "1vw"
    },
    labelDivV: {

    },
    labelH: {
        flexGrow: 4,
        fontSize: "2vw"
    },
    labelV: {
        flexGrow: 4,
        fontSize: "3vw",
        paddingBottom: "1vh"
    },
    markLabel: {
        color: "green",
        fontSize: "2.6vmin",
        transform: "rotate(50deg) translateY(3.5vmin)",
    },
    markLabelActive: {
        color: "red",
    },
    rail: {
        color: "yellow"
    },
    btnHDiv: {
        display: "inline",
    },
    btnVDiv: {
        backgroundColor: "green",
        padding: "0",
        margin: "0",
        textAlign: "top",
        height: "3px",
        '& > *': {
            margin: 0,
        }
    },
    btnH: {
        color: "yellow",
        backgroundColor: "lightskyblue",
        fontSize: "2vmin",
        boxShadow: "none",
        alignSelf: "top",
        textAlign: "top",
        margin: "0"
    },
    btnV: {
        color: "yellow",
        backgroundColor: "lightskyblue",
        fontSize: "2vmin",
        boxShadow: "none",
        alignSelf: "top",
        textAlign: "top",
        marginBottom: "1vh",
        width: "max-content"
    },
    gridH: {
        marginBottom: "6vh",
        justify: "space-between",
    },
    gridV: {
        width: "33%",
        flexDirection: "column",
        justify: "space-between",
    }
});



/**
 * INFRA-LOW (<.5HZ)
Infra-Low brainwaves (also known as Slow Cortical Potentials), are thought to be the basic cortical rythms that underlie our higher brain functions. Very little is known about infra-low brainwaves. Their slow nature make them difficult to detect and accurately measure, so few studies have been done. They appear to take a major role in brain timing and network function. 
DELTA WAVES (.5 TO 3 HZ)
Delta Waves, the slowest but loudest brainwaves

Delta brainwaves are slow, loud brainwaves (low frequency and deeply penetrating, like a drum beat). They are generated in deepest meditation and dreamless sleep. Delta waves suspend external awareness and are the source of empathy. Healing and regeneration are stimulated in this state, and that is why deep restorative sleep is so essential to the healing process.

THETA WAVES (3 TO 8 HZ)
Theta brainwaves, occur in sleep and are also dominant in deep meditation.

Theta brainwaves occur most often in sleep but are also dominant in deep meditation. Theta is our gateway to learning, memory, and intuition. In theta, our senses are withdrawn from the external world and focused on signals originating from within. It is that twilight state which we normally only experience fleetingly as we wake or drift off to sleep. In theta we are in a dream; vivid imagery, intuition and information beyond our normal conscious awareness. It’s where we hold our ‘stuff’, our fears, troubled history, and nightmares.

ALPHA WAVES (8 TO 12 HZ)
Alpha brainwaves occur during quietly flowing thoughts, but not quite meditation.

Alpha brainwaves are dominant during quietly flowing thoughts, and in some meditative states. Alpha is ‘the power of now’, being here, in the present. Alpha is the resting state for the brain. Alpha waves aid overall mental coordination, calmness, alertness, mind/body integration and learning.

BETA WAVES (12 TO 38 HZ)
Beta brainwaves are present in our normal waking state of consciousness.

Beta brainwaves dominate our normal waking state of consciousness when attention is directed towards cognitive tasks and the outside world. Beta is a ‘fast’ activity, present when we are alert, attentive, engaged in problem solving, judgment, decision making, or focused mental activity.

Beta brainwaves are further divided into three bands; Lo-Beta (Beta1, 12-15Hz) can be thought of as a 'fast idle', or musing. Beta (Beta2, 15-22Hz) is high engagement or actively figuring something out. Hi-Beta (Beta3, 22-38Hz) is highly complex thought, integrating new experiences, high anxiety, or excitement. Continual high frequency processing is not a very efficient way to run the brain, as it takes a tremendous amount of energy. 

GAMMA WAVES (38 TO 42 HZ)
Gamma brainwaves are the fastest of brain waves and relate to simultaneous processing of information from different brain areas

Gamma brainwaves are the fastest of brain waves (high frequency, like a flute), and relate to simultaneous processing of information from different brain areas. Gamma brainwaves pass information rapidly and quietly. The most subtle of the brainwave frequencies, the mind has to be quiet to access gamma. 

Gamma was dismissed as 'spare brain noise' until researchers discovered it was highly active when in states of universal love, altruism, and the ‘higher virtues’. Gamma is also above the frequency of neuronal firing, so how it is generated remains a mystery. It is speculated that gamma rhythms modulate perception and consciousness, and that a greater presence of gamma relates to expanded consciousness and spiritual emergence.
 */
const binHzMarksLong = [
    {
        value: 0,
        label: '0-4 Delta',
    },
    {
        value: 4,
        label: '4-8 Theta',
    },
    {
        value: 8,
        label: '8-12 Alpha',
    },
    {
        value: 12,
        label: '12 Low Beta',
    },
    {
        value: 15,
        label: '15 Mid Beta',
    },
    {
        value: 18,
        label: '18 High Beta',
    },
    {
        value: 30,
        label: '30+ Gamma',
    },
    {
        value: 64,
        label: '64',
    }
];
const binHzMarksShort = [
    {
        value: 0,
        label: '0-4 Delta',
    },
    {
        value: 4,
        label: '4-8 Theta',
    },
    {
        value: 8,
        label: '8-12 Alpha',
    },
    {
        value: 12,
        label: '12 Low Beta',
    },
    {
        value: 16,
        label: '16 Mid Beta',
    },
    {
        value: 18,
        label: '18 High Beta',
    }
];


export default function BinHz(props) {
    const maxBinMore = 64;
    const maxBinLess = 20;
    const more = 'High Range';
    const less = "Low Range";
    const classes = useStyles();
    const [binHz, setBinHz] = React.useState(0);
    const [btnTxt, setBtnTxt] = React.useState(more);
    const [maxBin, setMaxBin] = React.useState(maxBinLess);
    const isLandscape = useMediaQuery("(orientation: landscape)");


    React.useEffect(() => {
        //console.log('useEffect binHz changed', props.binHz);
        setBinHz(props.binHz);
        if (props.binHz > maxBinLess) {
            setBtnTxt(less);
            setMaxBin(maxBinMore);
        } else {
            setBtnTxt(more);
            setMaxBin(maxBinLess);
        }
    }, [props.binHz])

    //Slider change
    const handleBinHzChange = (event, newValue) => {
        setBinHz(newValue);
        props.handleBinHzChange(newValue);
        if (newValue > maxBinLess - 2) {
            setBtnTxt(less);
            setMaxBin(maxBinMore);
        } else {
            setBtnTxt(more);
            setMaxBin(maxBinLess);
        }
    };
    // input change
    const handleInputChange = event => {
        let value = Number(event.target.value);
        console.log('handleInputChange: value: ', value);
        if (isNaN(value)) {
            value = 0;
        }
        // if (value > maxBin) {
        //     value = maxBin;
        // }
        if (value > maxBinLess) {
            toggleIt(more);
        } else {
            toggleIt(less);
        }
        if (value > maxBinMore) {
            //    setMaxBin(maxBinMore);
            value = maxBinMore;
        }
        if (value < 0) {
            value = 0;
        }
        setBinHz(value);
        props.handleBinHzChange(value);
    };

    const handleBinHzBlur = () => {
        if (!binHz) {
            setBinHz(0);
        } else if (binHz > maxBin) {
            setBinHz(maxBin);
        }
    };

    // const toggle = () => {
    //     if (btnTxt === more) {
    //         setBtnTxt(less);
    //         setMaxBin(maxBinMore);
    //     } else {
    //         setBtnTxt(more);
    //         setMaxBin(maxBinLess);
    //         if (binHz > maxBinLess) {
    //             setBinHz(maxBinLess);
    //             props.handleBinHzChange(maxBinLess);
    //         }
    //     }
    // }
    const toggleIt = (value) => {
        if (value === more) {
            setBtnTxt(less);
            setMaxBin(maxBinMore);
        } else {
            setBtnTxt(more);
            setMaxBin(maxBinLess);
            if (binHz > maxBinLess) {
                setBinHz(maxBinLess);
                props.handleBinHzChange(maxBinLess);
            }
        }
    }


    return (
        // <div className={isLandscape === true ? classes.divLandscape : classes.divPortrait}>
        <Grid
            container
            className={isLandscape ? classes.gridH : classes.gridV}
        >
            <div className={isLandscape ? classes.labelDivH : classes.labelDivV}>
                <Typography
                    variant="h6"
                    className={isLandscape ? classes.labelH : classes.labelV}
                >
                    BinaryHz
                </Typography>
            </div>
            {/* {!isLandscape &&
                // <div className={classes.btnVDiv}>
                <Button
                    className={classes.btnV}
                    id="morelessV"
                    color={"inherit"}
                    onClick={toggle}
                >
                    {btnTxt}
                </Button>
                // </div>
            } */}
            <div
                className={
                    isLandscape === true
                        ? classes.sliderDivLandscape
                        : classes.sliderDivPortrait
                }
            >
                <Slider
                    orientation={isLandscape === true ? "horizontal" : "vertical"}
                    value={binHz}
                    max={maxBin}
                    min={0}
                    onChange={handleBinHzChange}
                    onBlur={handleBinHzBlur}
                    aria-labelledby="binary Hz slider"
                    marks={binHz <= maxBinLess ? binHzMarksShort : binHzMarksLong}
                    classes={{
                        markLabel: classes.markLabel,
                        markLabelActive: classes.markLabelActive,
                        rail: classes.rail
                    }}
                />
            </div>
            <div
                className={isLandscape ? classes.inputLandscape : classes.inputPortrait}
            >
                <Input
                    value={binHz ? binHz : 0}
                    onChange={handleInputChange}
                    onBlur={handleBinHzBlur}
                    disableUnderline={true}
                    inputProps={{
                        step: 0.1,
                        min: 0,
                        max: { maxBin },
                        type: "number"
                    }}
                />
            </div>
            {/* {isLandscape &&
                <Button
                    className={classes.btnH}
                    id="morelessV"
                    color={"inherit"}
                    onClick={toggle}
                >
                    {btnTxt}
                </Button>
            } */}
        </Grid>
    )
}
import React from "react";
import ReactDOM from "react-dom";
//import "./index.css";
import App from "./App";
import * as serviceWorker from "./service-worker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js").then((reg) => {
    // registration worked
    // console.log('Registration succeeded. Scope is ' + reg.scope);
    console.log("Applicaion may be installed")
  }).catch((error) => {
    // registration failed
    // console.log('Registration failed with ' + error);
    console.log("Unable to install application. Internet mode only.")
  });
}

import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    title: {
        fontWeight: "bold",
    },
    title2: {
        fontWeight: "bold",
        color: "darkgreen",
    },
    description: {
        marginLeft: "2vh",
        marginBottom: "0"
    },
    screen: {
        backgroundColor: "#e1f6fe"
    }
});

export default function About(props) {
    const classes = useStyles();
    return (
        <Typography component="div" className={classes.screen}>
            <h2>About Binaural Bee</h2>
            <p>Welcome to Binaural Bee, a web application that
                uses your browser to generate binaural and isochronic beats of your choice.</p>
            <p>Binaural Bee generates sine waves at adjustable frequencies, these waves are then combined to produce customized binaural or isochronic (monaural) tones.</p>
            <p> BinauralBee is a progressive web app. Like a native app, it can be added to the homescreen, and it is fully functional offline.</p>
        </Typography>
    )
}
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SidebarItem from "../SidebarItem";

const useStyles = makeStyles({
    sidebar: {
        width: "30%",
        height: "90vh",
        backgroundColor: "rgba(78,112,224, 0.96)", // medium blue color
        position: "absolute",
        zIndex: "1200",
        marginTop: "2vmin"
        // cursor: "pointer",
    },
    ulList: {
        listStyle: "none",
        display: "block",
        textAlign: "center",
        paddingInlineStart: "2vmin",
        paddingInlineEnd: "2vmin",

    },
    // li: {
    //     cursor: "pointer"
    // }
    // ul: {
    //     display: block;
    //     list-style-type: disc;
    //     margin-block-start: 1em;
    //     margin-block-end: 1em;
    //     margin-inline-start: 0px;
    //     margin-inline-end: 0px;
    //     padding-inline-start: 40px;
    // }
});

export default function Sidebar(props) {
    const classes = useStyles();
    const doAbout = () => {
        props.setPage('about');
        props.toggleMenu();
    }
    const doControls = () => {
        props.setPage('controls');
        props.toggleMenu();
    }
    const doDisclaimer = () => {
        props.setPage('disclaimer');
        props.toggleMenu();
    }
    const doHowto = () => {
        props.setPage('howto');
        props.toggleMenu();
    }
    const doPresets = () => {
        props.setPage('presets');
        props.toggleMenu();
    }


    return (<div className={classes.sidebar}>
        <ul className={classes.ulList}>
            <li onClick={doControls}><SidebarItem label="Home" /></li>
            <li onClick={doPresets}><SidebarItem label="Presets" /></li>
            <li onClick={doAbout}><SidebarItem label="About" /></li>
            <li onClick={doHowto}><SidebarItem label="How to" /></li>
            <li onClick={doDisclaimer}><SidebarItem label="Disclaimer" /></li>
        </ul>
    </div>)

}
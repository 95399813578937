import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    presetDiv: {
        backgroundColor: "#e1f6fe",// very light blue-green, FYI LightSkyBlue = #87CEFA (darker)e1f6fe e5fff9
        margin: "0 0 2vh 0",
        // border: "3px solid",
        borderRadius: "0.5vmin",
        overflow: "hidden",
        // color: "DarkViolet"
    },
    nameDiv: {
        backgroundColor: "inherit",
        color: "darkgreen",
        fontWeight: "bold"
    },
    infoDiv: {
        display: "flex",
        marginLeft: "8vh"
    },
    descriptionDiv: {
        backgroundColor: "inherit",
        whiteSpace: "pre-line"
    }
});

export default function Preset(props) {
    const classes = useStyles();
    const handleClick = function (selection) {
        props.onSelect(selection);
    };
    // const stopPropagation = function (e) {
    //     e.stopPropagation();
    // };
    return (
        <>
            <Typography className={classes.presetDiv} data-name={props.preset.name} onClick={() => handleClick(props.preset.name)}>
                {/* <div className={classes.infoDiv}> */}
                {/* <div className={classes.nameDiv}>{props.preset.displayName}</div>
                <div className={classes.descriptionDiv}>{props.preset.desc}</div> */}
                {/* </div> */}
                <span className={classes.nameDiv}>{props.preset.displayName}</span>
                <br /><span className={classes.descriptionDiv}>{props.preset.desc}</span>
            </Typography>
        </>
    )

}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Preset from "../Preset";

const useStyles = makeStyles({

    ulList: {
        listStyle: "none",
        display: "block",
        // backgroundColor: "LightSkyBlue"
        // textAlign: "center",
        // paddingInlineStart: "2vmin",
        // paddingInlineEnd: "2vmin"

    },
    ul: {
        paddingInlineStart: "2vw",
        listStyle: "none",
    },
    li: {
        cursor: "pointer"
    }
});

export default function Presets(props) {
    const classes = useStyles();
    // const handleClick = function (e) {
    //     debugger
    //     props.onPresetSelect(e.target.getAttribute("data-key"));
    // };
    return (

        <div className={classes.ulList}>
            <ul className={classes.ul}>
                {props.presets.map(function (preset, idx) {
                    //return <li key={idx} data-key={preset.name} onClick={handleClick}>{preset.displayName}</li>
                    // return <li key={idx} data-key={preset.name} onClick={handleClick}>
                    return <li key={idx} className={classes.li}>
                        <Preset preset={preset} onSelect={props.onPresetSelect}></Preset>
                    </li>
                })}
            </ul>
        </div>
    )

}

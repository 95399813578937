import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    labelDiv: {
        margin: "10px 0 0 0",
        backgroundColor: "#ffff66",
        borderRadius: "2vmin",
        cursor: "pointer",
        fontSize: "4vmin",
        // fontFamily: '"Times New Roman", Times, sens-serif'
        // fontFamily: "'Long Cang', cursive"
        fontFamily: "'Acme', sans-serif"

    }
});

export default function SidebarItem(props) {
    const classes = useStyles();
    return (
        <Typography className={classes.labelDiv}>
            {props.label}
        </Typography>
    );
}
import React from "react";
import Button from "@material-ui/core/Button";
import VolumeSlider from "../VolumeSlider";
import BinHz from "../BinHz";
import BaseHz from "../BaseHz";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Timer from '../Timer';
import FormControl from '@material-ui/core/FormControl';


const controlsStyles = makeStyles(theme => ({
  landscapePanel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    color: "black",
    alignItems: "center",
    backgroundColor: "lightblue",
    height: "50vh",
  },
  portraitPanel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "lightblue",
  },
  landscapeRadios: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "lightblue",
    justifyContent: "center",
  },
  startAndTimerH: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: "2vmin",
    backgroundColor: "lightblue",
    padding: "1vmin 5vmin 2vh 5vmin",
    maxHeight: "8vh",
  },
  startAndTimerV: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: "2vmin",
    backgroundColor: "lightblue",
    padding: "3vmin 5vmin 2vh 5vmin",
    maxHeight: "8vh",
  },
  button: {
    width: "20vmin"
  },
  preset: {
    border: "1px solid #ff1122"
  },
  panel: {
    height: "50%",
  },
  presetDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: "4vmin",
    backgroundColor: "lightBlue",
    color: "green",
    minHeight: "6vh"
  },
  addPresetDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    fontSize: "4vmin",
    backgroundColor: "lightBlue",
    minHeight: "6vh",
    paddingLeft: "12vw"
  },
  landscape: {
    flexDirection: "column",
    justifyContent: "space-around",
  },
  portrait: {
    backgroundColor: "limegreen"
  },
  gridH: {
    padding: "0 2vw 0 2vw",
    direction: "row",
    backgroundColor: "lightBlue"
  },
  gridV: {
    flexWrap: "unset",
    flexDirection: "row",
    backgroundColor: "lightblue",
    alignItems: "start",
    paddingLeft: "16%"
  }
}))


function BinauralControls(props) {
  const isLandscape = useMediaQuery("(orientation: landscape)");
  const classes = controlsStyles();
  const [mode, setMode] = React.useState("binaural");
  const presetLabel = `Preset: `;

  React.useEffect(() => {
    // console.log('mode changed', props.mode);
    setMode(props.mode);
  }, [props.mode])

  const handleModeChange = event => {
    // setMode(event.target.value);
    props.handleModeChange(event.target.value);
  };
  return (
    <FormControl component="fieldset" >
      {/* <div> */}
      <div className={classes.landscapeRadios}>
        <RadioGroup
          style={{
            display: "flex",
            flexDirection: "row",
          }}
          aria-label="mode"
          name="mode"
          value={mode}
          onChange={handleModeChange}
        >
          <FormControlLabel
            value="binaural"
            control={<Radio
              checked={mode === "binaural"}
            />}
            label="Binaural"
          />
          <FormControlLabel
            value="isochronic"
            control={<Radio
              checked={mode === "isochronic"} />}
            label="Isochronic"
          />
        </RadioGroup>
      </div>
      <Grid
        container
        className={isLandscape ? classes.gridH : classes.gridV}
      >
        <Grid item xs={12}>
          <BaseHz handleBaseHzChange={props.handleBaseHzChange} baseHz={props.baseHz} />
        </Grid>
        <Grid item xs={12}>
          <BinHz handleBinHzChange={props.handleBinHzChange} binHz={props.binHz} />
        </Grid>
        <Grid item xs={12}>
          <VolumeSlider handleVolumeChange={props.handleVolumeChange} />
        </Grid>
      </Grid>
      <div className={isLandscape ? classes.startAndTimerH : classes.startAndTimerV}>
        <Button
          className={classes.button}
          id="BtnB"
          color={props.btnTxt === "START" ? "primary" : "secondary"}
          variant="contained"
          onClick={props.toggle}
        >
          {props.btnTxt}
        </Button>
        <Timer isPlaying={props.isPlaying} stop={props.stop} />
      </div>
      {/* {!props.preset &&
        <div className={classes.addPresetDiv}>
          <AddBoxOutlinedIcon />Save settings as preset
        </div>
      } */}
      <div className={classes.presetDiv}>{props.preset ? presetLabel + props.preset[0].displayName : null}</div>
    </FormControl >
  )
}

export default BinauralControls;

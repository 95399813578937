import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles({
  divLandscape: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    // alignItems: "space-evenly",
    // alignContent: "center",
    marginBottom: "3vh",
    width: "90%"
  },
  div2Landscape: {
    display: "inline",
    width: "10%",
    alignItems: "top"
  },
  divPortrait: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  sliderDivLandscape: {
    width: "70%",
    marginLeft: "1vw"
  },
  sliderDivPortrait: {
    height: "50vh"
  },
  inputLandscape: {
    marginLeft: "2vw",
    marginRight: "1vw"
  },
  inputPortrait: {
    width: 42,
    marginTop: "3vh",
  },
  labelDivH: {
    width: "8vw",
    marginRight: "1vw"
  },
  labelDivV: {
    // marginBottom: "3vh"
  },
  labelH: {
    flexGrow: 4,
    fontSize: "2vw"
  },
  labelV: {
    flexGrow: 4,
    fontSize: "3vw"
  },
  markLabel: {
    color: "green",
    fontSize: "2.6vmin",
    transform: "rotate(50deg)",
    labelAlign: "left"
  },
  markLabelActive: {
    color: "red",
  },
  rail: {
    color: "yellow"
  },
  btnHDiv: {
    display: "inline",
    marginLeft: "2vh"
  },
  btnH: {
    color: "yellow",
    backgroundColor: "lightskyblue",
    fontSize: "2vmin",
    boxShadow: "none",
    alignSelf: "top",
    textAlign: "top",
    margin: "0"
  },
  btnV: {
    color: "yellow",
    backgroundColor: "lightskyblue",
    fontSize: "2vmin",
    boxShadow: "none",
    alignSelf: "top",
    textAlign: "top",
    marginBottom: "1vh",
    width: "max-content"
  },
  gridH: {
    // marginBottom: "6vh",
    justify: "space-between",
    marginTop: "2vh"
  },
  gridV: {
    width: "33%",
    // backgroundColor: "lightgreen",
    flexDirection: "column"
  }
});

const volumeMarksHigh = [
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  }
];

const volumeMarksLow = [
  {
    value: 0.5,
    label: '0.5',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 1.5,
    label: '1.5',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 2.5,
    label: '2.5',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 3.5,
    label: '3.5',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 4.5,
    label: '4.5',
  },
  {
    value: 5,
    label: '5',
  }
];

export default function VolumeSlider(props) {
  const classes = useStyles();
  const [volume, setVolume] = React.useState(4);
  const isLandscape = useMediaQuery("(orientation: landscape)");
  const maxVolMore = 100;
  const maxVolLess = 5;
  const more = "High Volume";
  const less = "Low Volume";
  const [btnTxt, setBtnTxt] = React.useState(more);
  const [maxVol, setMaxVol] = React.useState(maxVolLess);

  // Slider changes value
  const handleVChange = (event, newValue) => {
    props.handleVolumeChange(newValue);
    setVolume(newValue);
  };

  // input changes value
  const handleVolumeChange = event => {
    // var maxVol = 100;
    let vol = event.target.value;
    if (!vol || vol < 0) {
      vol = 0;
    } else if (vol > maxVol) {
      vol = maxVol;
    }
    setVolume(vol);
    props.handleVolumeChange(vol);
  };

  const handleVolumeBlur = () => {
    //console.log("volume: " + volume);
    //var maxVol = props.tab === 0 ? 100 : 50;
    // var maxVol = 100;
    // if (!volume || volume < 0) {
    //   setVolume(0);
    // } else if (volume > maxVol) {
    //   setVolume(maxVol);
    // } else {
    //   setVolume(volume);
    // }
  };
  const toggle = () => {
    if (btnTxt === more) {
      setBtnTxt(less);
      setMaxVol(maxVolMore);
    } else {
      setBtnTxt(more);
      setMaxVol(maxVolLess);
      if (volume > maxVolLess) {
        setVolume(maxVolLess);
        props.handleVolumeChange(maxVolLess);
      }
    }
  }

  return (
    // <div className={isLandscape === true ? classes.divLandscape : classes.divPortrait}>
    <Grid
      container
      className={isLandscape ? classes.gridH : classes.gridV}
    >
      <div className={isLandscape === true ? classes.labelDivH : classes.labelDivV} >
        <Typography
          variant="h6"
          className={isLandscape === true ? classes.labelH : classes.labelV}
        >
          Volume
        </Typography>
      </div>
      {!isLandscape &&
        <div className={classes.btnVDiv}>
          <Button
            className={classes.btnV}
            id="volMorelessV"
            onClick={toggle}
          >
            {btnTxt}
          </Button>
        </div>
      }
      <div
        className={
          isLandscape === true
            ? classes.sliderDivLandscape
            : classes.sliderDivPortrait
        }
      >
        <Slider
          orientation={isLandscape === true ? "horizontal" : "vertical"}
          value={volume}
          max={maxVol}
          min={0}
          onChange={handleVChange}
          onBlur={handleVolumeBlur}
          aria-labelledby="volume-slider"
          marks={btnTxt === more ? volume <= maxVolLess ? volumeMarksLow : volumeMarksHigh : volumeMarksHigh}
          step={btnTxt === more ? .1 : 1}
          classes={{
            markLabel: classes.markLabel,
            markLabelActive: classes.markLabelActive,
            rail: classes.rail
          }}
        />
      </div>


      <div
        className={
          isLandscape === true ? classes.inputLandscape : classes.inputPortrait
        }
      >
        <Input
          // className={classes.input}
          value={volume}
          onChange={handleVolumeChange}
          onBlur={handleVolumeBlur}
          disableUnderline={true}
          inputProps={{
            step: btnTxt === more ? .1 : 1,
            min: 0,
            max: { maxVol },
            type: "number"
          }}
        />
      </div>
      {isLandscape &&
        <Button
          className={classes.btnH}
          id="volMorelessH"
          onClick={toggle}
        >
          {btnTxt}
        </Button>
      }
    </Grid>
  );
}

import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import Grid from '@material-ui/core/Grid';

//import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
//import getMuiTheme from 'material-ui/core/styles/getMuiTheme';
//import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
//import theme from './theme';



const useStyles = makeStyles({
  divLandscape: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    // alignContent: "center",
    marginBottom: "3vh",
    width: "90%"
  },
  divPortrait: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "space-around",
  },
  sliderDivLandscape: {
    width: "70%"
  },
  sliderDivPortrait: {
    height: "54vh"
  },
  gridH: {
    marginBottom: "6vh",
    justify: "space-between",
  },
  gridV: {
    width: "33%",
    flexDirection: "column",
    justify: "space-between",
  },
  inputLandscape: {
    marginLeft: "3vw"
  },
  inputPortrait: {
    width: 42,
    marginTop: "3vh",
    // backgroundColor: "white"
  },
  labelDivH: {
    width: "8vw",
    marginRight: "1vw"
  },
  labelDivV: {
    // marginBottom: "3vh"
  },
  labelH: {
    flexGrow: 4,
    fontSize: "2vw"
  },
  labelV: {
    flexGrow: 4,
    fontSize: "3vw",
    paddingBottom: "1vh"
  },
  markLabel: {
    color: "green",
    fontSize: "2.6vmin",
    transform: "rotate(50deg) translateY(2vmin)",
  },
  markLabelActive: {
    color: "red",
  },
  rail: {
    color: "yellow"
  },
  input: {
    borderRadius: "10px"
  },
  // spacerDiv: {
  //   minWidth: "40px", // same as mui button
  //   // backgroundColor: "aquamarine",
  //   minHeight: "20px"
  // }
});

// const sliderStyle = makeStyles({
//   color: "red",
//   markLabel: {
//     color: "pink"
//   }
// });

// const muiTheme = createMuiTheme({
//   Slider: {
//     color: 'yellow',
//     markLabel: {
//       color: "pink"
//     }
//   },
// })();


/**
 * Base Hz marks
 * Solfiggio tones
 * According to Leonard G. Horowitz.
 * https://roelhollander.eu/en/tuning-frequency/ancient-solfeggio-frequencies/

The proclaimed “Solfeggio frequencies” are:

UT – 396 Hz – Liberating Guilt and Fear
RE – 417 Hz – Undoing Situations and Facilitating Change
MI – 528 Hz – Transformation and Miracles (DNA Repair)
FA – 639 Hz – Connecting/Relationships
SOL – 741 Hz – Awakening Intuition
LA – 852 Hz – Returning to Spiritual Order

Stradivari/Verdi Tuning (A = 432 hz, C = 256 hz), calculated using the Pythagorean method of 3:2 ratio for dominants, 11:8 for sub-dominants, 2:1 for octaves.
Note Frequency (hz)

- Mid Low (1 octave below middle C)

C 128
D 144
E 162
F 176
G 192
A 216
B 243

-- Mid (middle C)

C 256
D 288
E 324
F 352
G 384
A 432
B 486

-- Mid High (1 octave above middle C)

C 512
D 576
E 648
F 704
G 768
A 864
B 972
 */

//// BaseHz marks based on A438 equal-tempered
const baseHzMarksA438 = [
  // {
  //   value: 32.55,
  //   label: 'C1 32.55',
  // },
  // {
  //   value: 36.54,
  //   label: 'D1 36.54',
  // },
  // {
  //   value: 41.02,
  //   label: 'E1 41.02',
  // },
  // {
  //   value: 43.46,
  //   label: 'F1 43.46',
  // },
  // {
  //   value: 48.78,
  //   label: 'G1 48.78',
  // },
  // {
  //   value: 54.75,
  //   label: 'A1 54.75',
  // },
  // {
  //   value: 61.45,
  //   label: 'B1 61.45',
  // },
  {
    value: 65.11,
    label: 'C2 32.55',
  },
  // {
  //   value: 73.08,
  //   label: 'D2 73.08',
  // },
  // {
  //   value: 82.03,
  //   label: 'E2 82.03',
  // },
  // {
  //   value: 86.91,
  //   label: 'F2 86.91',
  // },
  // {
  //   value: 97.55,
  //   label: 'G2 97.55',
  // },
  // {
  //   value: 109.50,
  //   label: 'A2 109.50',
  // },
  // {
  //   value: 122.91,
  //   label: 'B2 122.91',
  // },
  // {
  //   value: 130.22,
  //   label: 'C3 130.22',
  // },
  // {
  //   value: 146.16,
  //   label: 'D3 146.16',
  // },
  // {
  //   value: 164.06,
  //   label: 'E3 164.06',
  // },
  // {
  //   value: 173.82,
  //   label: 'F3 173.82',
  // },
  // {
  //   value: 195.11,
  //   label: 'G3 195.11',
  // },
  // {
  //   value: 219.00,
  //   label: 'A3 219.00',
  // },
  // {
  //   value: 245.82,
  //   label: 'B3 245.82',
  // },
  {
    value: 260.44,
    label: 'C4 260.44/nMiddle C',
  },
  // {
  //   value: 292.33,
  //   label: 'D4 292.33',
  // },
  // {
  //   value: 328.13,
  //   label: 'E4 328.13',
  // },
  // {
  //   value: 347.64,
  //   label: 'F4 347.64',
  // },
  // {
  //   value: 390.21,
  //   label: 'G4 390.21',
  // },
  {
    value: 438.00,
    label: 'A4 438',
  },
  // {
  //   value: 491.64,
  //   label: 'B4 491.64',
  // },
  // {
  //   value: 520.87,
  //   label: 'C5 520.87',
  // },
  // {
  //   value: 584.66,
  //   label: 'D5 584.66',
  // },
  // {
  //   value: 656.26,
  //   label: 'E5 656.26',
  // },
  // {
  //   value: 695.28,
  //   label: 'F5 695.28',
  // },
  {
    value: 780.43,
    label: 'G5 780.43',
  },
  // {
  //   value: 876.00,
  //   label: 'A5 876.00',
  // },
  {
    value: 983.28,
    label: 'B5 983.28',
  },
  // {
  //   value: 1041.74,
  //   label: 'C6 1041.74',
  // }
]

const baseHzMarksA440 = [
  // {
  //   value: 32.70,
  //   label: 'C1 32.70',
  // },
  {
    value: 65.41,
    label: 'C2 65.41',
  },
  // {
  //   value: 130.81,
  //   label: 'C3 130.81',
  // },
  {
    value: 260.44,
    label: 'C4 261.63',
  },
  {
    value: 440.00,
    label: 'A4 440',
  },
  {
    value: 587.33,
    label: 'D5 587.33',
  },
  {
    value: 783.99,
    label: 'G5 783.99',
  },
  {
    value: 987.77,
    label: 'B5 987.77',
  },
]

////////
/// A440 values
// Note	Frequency (Hz)	Wavelength (cm)
// C0	16.35	2109.89
//  C#0/Db0 	17.32	1991.47
// D0	18.35	1879.69
//  D#0/Eb0 	19.45	1774.20
// E0	20.60	1674.62
// F0	21.83	1580.63
//  F#0/Gb0 	23.12	1491.91
// G0	24.50	1408.18
//  G#0/Ab0 	25.96	1329.14
// A0	27.50	1254.55
//  A#0/Bb0 	29.14	1184.13
// B0	30.87	1117.67
// C1	32.70	1054.94
//  C#1/Db1 	34.65	995.73
// D1	36.71	939.85
//  D#1/Eb1 	38.89	887.10
// E1	41.20	837.31
// F1	43.65	790.31
//  F#1/Gb1 	46.25	745.96
// G1	49.00	704.09
//  G#1/Ab1 	51.91	664.57
// A1	55.00	627.27
//  A#1/Bb1 	58.27	592.07
// B1	61.74	558.84
// C2	65.41	527.47
//  C#2/Db2 	69.30	497.87
// D2	73.42	469.92
//  D#2/Eb2 	77.78	443.55
// E2	82.41	418.65
// F2	87.31	395.16
//  F#2/Gb2 	92.50	372.98
// G2	98.00	352.04
//  G#2/Ab2 	103.83	332.29
// A2	110.00	313.64
//  A#2/Bb2 	116.54	296.03
// B2	123.47	279.42
// C3	130.81	263.74
//  C#3/Db3 	138.59	248.93
// D3	146.83	234.96
//  D#3/Eb3 	155.56	221.77
// E3	164.81	209.33
// F3	174.61	197.58
//  F#3/Gb3 	185.00	186.49
// G3	196.00	176.02
//  G#3/Ab3 	207.65	166.14
// A3	220.00	156.82
//  A#3/Bb3 	233.08	148.02
// B3	246.94	139.71
// C4	261.63	131.87
//  C#4/Db4 	277.18	124.47
// D4	293.66	117.48
//  D#4/Eb4 	311.13	110.89
// E4	329.63	104.66
// F4	349.23	98.79
//  F#4/Gb4 	369.99	93.24
// G4	392.00	88.01
//  G#4/Ab4 	415.30	83.07
// A4	440.00	78.41
//  A#4/Bb4 	466.16	74.01
// B4	493.88	69.85
// C5	523.25	65.93
//  C#5/Db5 	554.37	62.23
// D5	587.33	58.74
//  D#5/Eb5 	622.25	55.44
// E5	659.25	52.33
// F5	698.46	49.39
//  F#5/Gb5 	739.99	46.62
// G5	783.99	44.01
//  G#5/Ab5 	830.61	41.54
// A5	880.00	39.20
//  A#5/Bb5 	932.33	37.00
// B5	987.77	34.93
// C6	1046.50	32.97
//  C#6/Db6 	1108.73	31.12
// D6	1174.66	29.37
//  D#6/Eb6 	1244.51	27.72
// E6	1318.51	26.17
// F6	1396.91	24.70
//  F#6/Gb6 	1479.98	23.31
// G6	1567.98	22.00
//  G#6/Ab6 	1661.22	20.77
// A6	1760.00	19.60
//  A#6/Bb6 	1864.66	18.50
// B6	1975.53	17.46



//////////////////
// const baseHzMarks = [
//   {
//     value: 128,
//     label: 'C 128',
//   },
//   {
//     value: 144,
//     label: 'D 144',
//   },
//   {
//     value: 162,
//     label: 'E 162',
//   },
//   {
//     value: 176,
//     label: 'F 176',
//   },
//   {
//     value: 192,
//     label: 'G 192',
//   },
//   {
//     value: 216,
//     label: 'A 216',
//   },
//   {
//     value: 243,
//     label: 'B 243',
//   },
//   {
//     value: 256,
//     label: 'C 256',
//   },
//   {
//     value: 288,
//     label: 'D 288',
//   },
//   {
//     value: 324,
//     label: 'E 324',
//   },
//   {
//     value: 352,
//     label: 'F 352',
//   },
//   {
//     value: 384,
//     label: 'G 384',
//   },
//   {
//     value: 432,
//     label: 'A 432',
//   },
//   {
//     value: 486,
//     label: 'B 486',
//   },
//   {
//     value: 512,
//     label: 'C 512',
//   },
//   {
//     value: 576,
//     label: 'D 576',
//   },
//   {
//     value: 648,
//     label: 'E 648',
//   },
//   {
//     value: 704,
//     label: 'F 704',
//   },
//   {
//     value: 768,
//     label: 'G 768',
//   },
//   {
//     value: 864,
//     label: 'A 864',
//   },
//   {
//     value: 972,
//     label: 'B 972',
//   },

//   // "ESOTERIC" values:"
const baseHzMarksLeonardHorowitz = [
  //   // {
  //   //   value: 174,
  //   //   label: '174',
  //   // }, {
  //   //   value: 285,
  //   //   label: '285',
  //   // },
  //   // {
  //   //   value: 396,
  //   //   label: '396',
  //   // },
  //   // {
  //   //   value: 417,
  //   //   label: '417',
  //   // },
  //   // {
  //   //   value: 528,
  //   //   label: '528',
  //   // },
  //   // {
  //   //   value: 639,
  //   //   label: '639',
  //   // },
  //   // {
  //   //   value: 741,
  //   //   label: '741',
  //   // }, {
  //   //   value: 852,
  //   //   label: '852',
  //   // },
];

export default function BaseHz(props) {
  const maxBaseHzValue = 1000;
  const classes = useStyles();
  const [baseHz, setBaseHz] = React.useState(220);
  // const [useTimer, setUseTimer] = React.useState(false);
  const isLandscape = useMediaQuery("(orientation: landscape)");
  const label = isLandscape ? "Base       Hz" : "BaseHz";
  //slider changes
  const handleBaseHzChange = (event, newValue) => {
    props.handleBaseHzChange(newValue);
    setBaseHz(newValue);
  };
  //Input field changes
  const handleInputChange = event => {
    let value = Number(event.target.value);
    if (isNaN(value)) {
      value = 0;
    }
    if (value > maxBaseHzValue) {
      value = maxBaseHzValue
    }
    if (value < 0) {
      value = 0;
    }
    setBaseHz(value);
    props.handleBaseHzChange(value);
  };

  const handleBaseHzBlur = () => {
    if (baseHz < 0) {
      setBaseHz(0);
    } else if (baseHz > maxBaseHzValue) {
      setBaseHz(maxBaseHzValue);
    }
  };

  return (
    <>
      <Grid
        container
        className={isLandscape ? classes.gridH : classes.gridV}
      >
        <div
          className={isLandscape === true ? classes.labelDivH : classes.labelDivV}
        >
          <Typography
            variant="h6"
            className={isLandscape === true ? classes.labelH : classes.labelV}
          >
            {label}
          </Typography>
        </div>
        {/* {
          !isLandscape &&
          <div className={classes.spacerDiv}></div>
        } */}
        <div
          className={
            isLandscape === true
              ? classes.sliderDivLandscape
              : classes.sliderDivPortrait
          }
        >
          <Slider
            orientation={isLandscape === true ? "horizontal" : "vertical"}
            value={props.baseHz}
            max={1000}
            min={0}
            onChange={handleBaseHzChange}
            onBlur={handleBaseHzBlur}
            aria-labelledby="baseHz-slider"
            marks={baseHzMarksA440}
            classes={{
              markLabel: classes.markLabel,
              markLabelActive: classes.markLabelActive,
              rail: classes.rail
            }}
          />
        </div>
        <div
          className={
            isLandscape === true ? classes.inputLandscape : classes.inputPortrait
          }
        >
          <Input
            className={classes.input}
            value={props.baseHz}
            onChange={handleInputChange}
            onBlur={handleBaseHzBlur}
            disableUnderline={true}
            inputProps={{
              step: 1,
              min: 0,
              max: 1000,
              type: "number"
            }}
          />
        </div>
        {/* {
          isLandscape &&
          <div className={classes.spacerDiv}></div>
        } */}
        {/* </div> */}
      </Grid>
    </>
  );
}

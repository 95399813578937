import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    title: {
        fontWeight: "bold",
    },
    title2: {
        fontWeight: "bold",
        color: "darkgreen",
    },
    description: {
        marginLeft: "2vh",
        marginBottom: "0"
    },
    screen : {
        backgroundColor: "#e1f6fe"
    }
});
const binHzDesc = <p><b>1.</b> Adjust the <b>binary frequency (BinHz)</b> to the desired brainwave frequency.
The available range is 0-64Hz. Most practical applications will be in the 0.5-20Hz range. The range descriptions below
provide a quick overview of the 5 brainwave classifications</p>
const baseHzDesc = <p><b>2.</b> Adjust the <b>carrier frequency (BaseHz)</b>. This is the base tone, usually adjusted to a
 frequency that is comfortable to the user. Generally speaking, lower BaseHz frequencies pair well with lower BinHz values, 
 and higher BaseHz frequencies pair well with higher BinHz values. Specific frequencies may also be used, for example 528 or
432 are popular choices.
 <br />NOTE: The BaseHz <b>note</b> values (C2-B5) are based on the A440 equal-tempered scale.</p>
const volumeDesc = <p><b>3.</b> Adjust the volume down to a point where the beats are perceived as background sound.
Do not "actively listen" to the beats, they should be barely perceptible. You may use them in conjuction with other activites
such as watching a video(theta), meditation(alpha) or studying(beta).
<br />- For binaural beats, BaseHz will be in the left ear, and (BaseHz + BinHz) will be in the right ear.
<br />- For isochronic beats, BaseHz is in both ears, and the volume is modulated @ BinHz/second.
<br />- For precise frequency values (decimal values, like 2.79 or 12.65), you may find it easier to enter values into the fields using the keypad.</p>
const deltaDesc = 'Slow frequency observed in deep, dreamless sleep. Associated with the unconscious mind and healing.'
const thetaDesc = 'Observed during light sleep and REM dreaming. Associated with meditation.'
const alphaDesc = 'Reflective, relaxation and creative visualization.'
const betaDesc = 'Active, wakeful alert state, logic and reasoning, goal-oriented tasks.'
const gammaDesc = 'Intense focus, concentration, learning.'
export default function HowTo(props) {
    const classes = useStyles();
    return (
        <Typography component="div" className={classes.screen}>
            `<div className={classes.title}>How to use Binaural Bee</div>
            <div>{binHzDesc}</div>
            <div>{baseHzDesc}</div>
            <div>{volumeDesc}</div> 
            <div className={classes.title}>Brainwave Approximate Frequency Ranges</div>
            <div className={classes.title2}>Delta waves (.5 to 4 Hz)</div>
            <div className={classes.description}>{deltaDesc}</div>
            <div className={classes.title2}>Theta waves (4 to 8 Hz)</div>
            <div className={classes.description}>{thetaDesc}</div>
            <div className={classes.title2}>Alpha waves (8 to 12 Hz)</div>
            <div className={classes.description}>{alphaDesc}</div>
            <div className={classes.title2}>Beta waves (12 to 40 Hz)</div>
            <div className={classes.description}>{betaDesc}</div>
            <div className={classes.title2}>Gamma ( 40+ Hz)</div>
            <div className={classes.description}>{gammaDesc}</div>
         `

        </Typography>
    );
}

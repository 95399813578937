import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';



const useStyles = makeStyles({
    timerDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        // marginLeft: "3vh",
        margin: "0 0 0 3vh",
        padding: "0",
        // backgroundColor: "red"
    },
    timerSection: {
        borderRadius: "10px",
        maxWidth: "20vmin",
        paddingTop: "2vh"
    },
    // hour: {
    //     borderRadius: "10px",
    //     maxWidth: "20vmin",
    //     // maxHeight: "3%",
    //     // alignSelf: "center"
    // },
    // minute: {
    //     borderRadius: "10px",
    //     maxWidth: "20vmin",
    //     paddingTop: "1vh"
    // },
    // second: {
    //     borderRadius: "10px",
    //     maxWidth: "20vmin",
    //     // height: "1vh"
    // },
    formTextInput: {
        fontSize: "4vmin"
    },
    inputField: {
        height: "2vh",
        backgroundColor: "green"
    }
});

export default function Timer(props) {
    const classes = useStyles();
    const [useTimer, setUseTimer] = React.useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [hours, setHours] = React.useState(0); // integer value
    const [minutes, setMinutes] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);
    const [hoursVal, setHoursVal] = React.useState("00");
    const [minutesVal, setMinutesVal] = React.useState("00");
    const [secondsVal, setSecondsVal] = React.useState("00");
    // const [timerIsRunning, setTimerIsRunning] = React.useState(false);
    const [timer, setTimer] = React.useState(null); // timer: Object created in setInterval 
    let secondsRemaining = 0;

    React.useEffect(() => {
        if (useTimer) {
            //  console.log('Timer on');
        } else {
            // console.log('Timer off.');
            clearClock();
        }
    }, [useTimer]);

    const handleChange = name => event => {
        setUseTimer(event.target.checked);
    };

    const clearClock = () => {
        setHours("00");
        setMinutes("00")
        setSeconds("00");
    }

    const calculateSeconds = () => {
        // console.log(hours + " " + minutes + " " + seconds);
        const hourSecs = hours * 60 * 60;
        const minuteSecs = minutes * 60;
        const secSecs = seconds * 1;
        let retVal = hourSecs + minuteSecs + secSecs;
        return retVal;
    }

    const makeNumbers = () => {
        let locHours = Math.trunc(secondsRemaining / 3600);
        let hoursRemainder = secondsRemaining % 3600;
        let locMin = Math.trunc(hoursRemainder / 60);
        let minutesRemainder = hoursRemainder % 60;
        setHours(locHours);
        setMinutes(locMin);
        setSeconds(minutesRemainder);
    }

    React.useEffect(() => {
        if (props.isPlaying) {
            setIsPlaying(true);
        } else {
            setIsPlaying(false);
        }
    }, [props.isPlaying, isPlaying]);

    React.useEffect(() => {
        setIsPlaying(false);
    }, [useTimer]);

    React.useEffect(() => {
        if (isPlaying) {
            if (useTimer && seconds) {
                let rem = calculateSeconds();
                secondsRemaining = rem;
                startTimer(true);
            }
            else {
                startTimer(false);
            }
        }
        else {
            startTimer(false);
        }
    }, [isPlaying])

    const countDownOne = () => {
        secondsRemaining--;
        makeNumbers();
        if (secondsRemaining <= 0) {
            setIsPlaying(false);
            setUseTimer(false);
        }
    }

    const startTimer = (start) => {
        if (start) {
            setTimer(setInterval(countDownOne, 1000));
            // console.log("timer start");
        }
        else {
            if (useTimer) {
                clearInterval(timer);
                setIsPlaying(false);
                props.stop();
                //  console.log("timer stop");
            }
        }
    }

    return (
        <div className={classes.timerDiv}>
            <Checkbox onChange={handleChange('isTimed')} disabled={isPlaying} checked={useTimer} />
            {/* <div className={classes.inputField}> */}
            <TextField
                disabled={!useTimer}
                id="hour"
                label="HH"
                type="number"
                value={hours}
                className={classes.timerSection}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                root={{
                    classes: {
                        inputProps: {
                            fontSize: "6px"
                        }
                    }
                }}
                onInput={(e) => {
                    let retVal = "00";
                    if (!isNaN(e.target.value)) {
                        let intVal = Math.max(0, parseInt(e.target.value));
                        let intStr = intVal.toString().slice(0, 2);
                        if (intStr.length < 2) {
                            intStr = "0" + intStr;
                        }
                        //  console.log("value: " + e.target.value);
                        const tens = parseInt(intStr.slice(0, 1));
                        const ones = parseInt(intStr.slice(1, 2));
                        const hours = (tens * 10) + ones;
                        // console.log("tens ones hours: " + tens + "::" + ones + "::" + hours);
                        retVal = intStr;
                        setHoursVal(hours);
                    }
                    setHours(retVal);
                }}
            />
            <TextField
                disabled={!useTimer}
                id="minute"
                label="MM"
                type="number"
                value={minutes}
                className={classes.timerSection}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                onInput={(e) => {
                    let retVal = "00";
                    if (!isNaN(e.target.value)) {
                        let intVal = Math.max(0, parseInt(e.target.value));
                        let intStr = intVal.toString().slice(0, 2);
                        if (intStr.length < 2) {
                            intStr = "0" + intStr;
                        }
                        let tens = parseInt(intStr.slice(0, 1));
                        if (tens && tens > 5) {
                            intStr = intStr.replace(tens, '5');
                            tens = 5;
                        }
                        const ones = parseInt(intStr.slice(1, 2));
                        const mins = (tens * 10) + ones;
                        retVal = intStr;
                        setMinutesVal(mins);
                    }
                    setMinutes(retVal);
                }}
            />
            <TextField
                disabled={!useTimer}
                id="seconds"
                label="SS"
                type="number"
                value={seconds}
                className={classes.timerSection}
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                onInput={(e) => {
                    let retVal = "00";
                    if (!isNaN(e.target.value)) {
                        let intVal = Math.max(0, parseInt(e.target.value));
                        let intStr = intVal.toString().slice(0, 2);
                        if (intStr.length < 2) {
                            intStr = "0" + intStr;
                        }
                        let tens = parseInt(intStr.slice(0, 1));
                        if (tens && tens > 5) {
                            intStr = intStr.replace(tens, '5');
                            tens = 5;
                        }
                        const ones = parseInt(intStr.slice(1, 2));
                        const secs = (tens * 10) + ones;
                        // console.log("secs int: " + secs);
                        retVal = intStr;
                        setSecondsVal(secs);
                    }
                    setSeconds(retVal);
                }}
            />
        </div >
    )
}
import React from "react";
import bee2 from "./BigBeeTransparent.svg";
import "./App.css";
import NoSleep from "nosleep.js";
import BinauralControls from "../src/Controls/BinauralControls";
import About from "../src/Layout/About";
import Sidebar from "../src/Layout/Sidebar";
import Disclaimer from "../src/Layout/Disclaimer";
import HowTo from "../src/Layout/HowTo";
import Presets from "../src/Layout/Presets";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from '@material-ui/icons/Menu'; // hamburger menu
// App Bar
import AppBar from '@material-ui/core/AppBar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// Google analytics
import ReactGA from 'react-ga';
const trackingId = "UA-178386762-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.set({
  //userId: auth.currentUserId(),
  // any data that is relevant to the user session
  // that you would like to track with google analytics
})





//////////


const theme = createMuiTheme({
  palette: {
    // https://material-ui.com/customization/color/
    primary: { main: '#3f51b5' },
    // primary: blue,
    secondary: { main: '#e53935' },
    //secondary: red,

  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      'Acme'
    ].join(','),
    // fontFamily: [
    //   'Long Cang',
    //   'cursive'
    // ]
    // fontFamily: [
    //   'Acme',
    //   'Arial',
    //   //'sans-serif',
    //   //'Roboto'
    // ]
  },
  overrides: {
    MuiFormControl: {
      root: {
        //     // border: "2px solid yellow",
        //     // marginTop: "2vh",
        display: "block"
      }
    },
    // MuiFormControl-root
    MuiFormControlLabel: {
      label: {
        fontSize: "2vmax",
      }
    },
    MuiButton: {
      root: {
        minWidth: "3vh",
        textTransform: "none"
      },
      label: {
        fontSize: "2vmin",

      }
    },
    MuiButtonBase: {
      root: {
        fontFamily: [
          'Acme',
          'sans-serif',
          'Roboto'
        ]
      }
    },
    MuiInputBase: {
      input: {
        backgroundColor: "white",
        borderRadius: "5px",
        width: "9vmin",
        minWidth: "2em",
        maxHeight: "50%",
        fontSize: "3vmin",
        padding: "6px 0 6px 6px"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "1vh"
      }
    }
  }
});

class App extends React.Component {

  constructor(props) {
    super(props);
    //  if (window.matchMedia('(display-mode: standalone)').matches) {
    // console.log("This is running as standalone.");
    // } else { console.log("NOT running as standalone!"); }

    // if (!('indexedDB' in window)) {
    // console.log('NOOO browser doesn\'t support IndexedDB');
    // } else {
    // console.log('YAY browser supports IndexedDB')
    // }
    if (typeof (Storage) !== "undefined") {
      // console.log('YAY browser supports Local Storage');
      this.loadDefaultPresets();
    } else {
      console.log('Unable to load presets: local storage not supported.');
    }

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioCtx = new AudioContext();
    const gainNode = audioCtx.createGain();
    const noSleep = new NoSleep();
    const presets = JSON.parse(localStorage.getItem('presets'));

    this.state = {
      leftHz: 220,
      rightHz: 220,
      baseHz: 220,
      binVal: 0.0,
      intensity: 100,
      audioCtx: audioCtx,
      gainNode: gainNode,
      noSleep: noSleep,
      volume: 3.5,
      tabValue: 0,
      btnTxt: "START",
      mode: "binaural",
      isPlaying: false,
      showMenu: false,
      page: "controls",
      presets: presets,
      preset: null
    };
  }

  static getDerivedStateFromProps(nextProps, previousState) {
    //console.log("getDerivedStateFromProps called, leftHz: " + previousState.leftHz);
    if (previousState.leftOsc) {
      let losc = previousState.leftOsc;
      //  console.log("original losc.frequency.value: " + losc.frequency.value)
      losc.frequency.value = previousState.leftHz;
      //  console.log("losc.frequency.value after setting to state.leftHz: " + losc.frequency.value);
      return { losc };
    } else {
      //   console.log("local losc.frequency.value: " + losc.frequency.value);
      return { leftOsc: null };
    }
  }

  sleep432Desc = 'Delta frequency over 432Hz base.\nUse at very low volumes to promote sleep.';
  sleep216Desc = 'Delta frequency over 216Hz base.\nUse at very low volumes to promote sleep.';
  theta364Desc = 'Theta frequency for relaxation over 364 base.\nUseful in mindfulness meditation or just \"zoning out\".';
  meditateDesc = 'Alpha frequency over 528 helps calm the mind. Focused meditation';
  neauseaDesc = 'Upset stomach help.\nTheta isochronic beat over low frequency helps calm stomach discomfort';
  headacheDesc = 'Headache reduction using a very slow Delta isochronic beat at low baseHz.\nPlay at low volume (almost inaudible).'
  lucidDreamingDesc = "Gamma 40Hz over 340Hz base.\nUse at very low volume, after initial sleep cycle";

  presets = [{ name: "sleep432", baseHz: "432", binHz: "3.2", mode: "binaural", desc: this.sleep432Desc, displayName: "Deep sleep over 423 base." },
  { name: "sleep216", baseHz: "216", binHz: "3.3", desc: this.sleep216Desc, mode: "binaural", displayName: "Deep sleep over 216 base." },
  { name: "relax", baseHz: "364", binHz: "5.7", desc: this.theta364Desc, mode: "binaural", displayName: "Relaxing theta frequency (\"zone out\")" },
  { name: "meditate", baseHz: "582", binHz: "8.3", desc: this.meditateDesc, mode: "binaural", displayName: "Alpha frequency for meditation support" },
  { name: "nausea", baseHz: "110", binHz: "5.14", desc: this.neauseaDesc, mode: "isochronic", displayName: "Pain relief for stomach discomfort" },
  { name: "headache", baseHz: "110", binHz: "1.2", desc: this.headacheDesc, mode: "isochronic", displayName: "Pain relief for headache" },
  { name: "luciddreaming", baseHz: "340", binHz: "40", desc: this.lucidDreamingDesc, mode: "binaural", displayName: "Gamma 40Hz Lucid Dreaming" }];

  loadDefaultPresets = () => {
    localStorage.setItem('presets', JSON.stringify(this.presets));
  }

  loadPreset = preset => {
    this.handleBaseHzChange(preset.baseHz);
    this.handleBinHzChange(preset.binHz);
    this.handleModeChange(preset.mode);
  }

  handleBaseHzChange = (value, resetPre = true) => {
    // console.log("handleBaseHzChange, value: " + value);
    if (resetPre) {
      this.setState({ preset: null });
    }
    this.setState(prevState => {
      return { leftHz: value }
    }, () => {
      let rval = this.state.leftHz + this.state.binVal;
      this.setState(prevState => { return { rightHz: rval } }, () => {
        this.setState(prevState => { return { baseHz: value } }, () => {
          if (this.state.leftOsc) {
            this.state.leftOsc.frequency.setValueAtTime(this.state.leftHz, 0); // value in hertz this.state.leftHz;
          }
          if (this.state.rightOsc) {
            this.state.rightOsc.frequency.setValueAtTime(this.state.rightHz, 0); // value in hertz this.state.rightHz;
          }
        });
      });
    });
  };



  handleBinHzChange = (value, resetPre = true) => {
    // console.log("handleBinHzChange, value: " + value);
    if (resetPre) {
      this.setState({ preset: null });
    }
    const bVal = parseFloat(value);
    this.setState({ binVal: bVal }, () => {
      const newRVal = parseInt(this.state.leftHz) + bVal;
      this.setState({ rightHz: newRVal }, () => {
        if (this.state.rightOsc) {
          this.state.rightOsc.frequency.setValueAtTime(this.state.rightHz, 0); // value in hertz this.state.rightHz;
        }
      });
    });
    this.setState({ binVal: bVal }, () => {
      if (this.state.LFO) {
        this.state.LFO.frequency.setValueAtTime(this.state.binVal, 0); // value in hertz this.state.LFO;
      }
    });
  };

  handleVolumeChange = value => {
    this.setState({ volume: value });
    if (this.state.mode === "isochronic") {
      this.state.gainNode.gain.setValueAtTime(value / 100 / 2, 0);
    } else {
      this.state.gainNode.gain.setValueAtTime(value / 100, 0);
    }
  };
  handleModeChange = (value, resetPre = true) => {

    if (resetPre) {
      this.setState({ preset: null });
    }
    this.setState({ mode: value }, () => {
      // console.log("handleModeChange, value: " + value);
      this.stop()
    });
  };

  handleIntensityChange = value => {
    this.setState({ intensity: value });
    //let currVal = this.state.gainNode.gain.value;
    // let currVal = this.state.lfoGain.gain.value;
    //  let coeficient = value / 100;
    //console.log(" >>> lfoGain.value currVal: " + currVal);
    //  let newVal = currVal * coeficient;
    // console.log(" >>> lfoGain.value, newVal: " + newVal);
    //  this.state.lfoGain.gain.setValueAtTime(coeficient, 0);
  };

  toggle = () => {
    this.toggleSound();
    if (this.state.btnTxt === "START") {
      this.setState({ btnTxt: "STOP" });
      ReactGA.event({
        category: "Begin",
        action: "User began session",
      });
    } else {
      this.setState({ btnTxt: "START" });
      ReactGA.event({
        category: "End",
        action: "User ended session",
      });
    }
  };

  toggleSound = () => {
    if (this.state.btnTxt === "START") {
      this.play();
    } else {
      this.stop();
    }
  };

  /**
   * TEST to test Csound import
   */
  playTest = () => {
    // console.log("playTest");
    //debugger;
    //const processor = this.state.processor;
    //CsoundObj.importScripts();
    //const CSObj = new CsoundObj();
    //debugger;
    // CSObj.importScripts();
    // CSObj.importScripts();
  };

  play = () => {
    this.setState({ isPlaying: true }, () => {
      if (this.state.mode === "binaural") {
        this.playBinaural();
      }
      if (this.state.mode === "isochronic") {
        this.playIsochronic();
      }
    })
  }

  playBinaural = () => {
    this.state.gainNode.gain.setValueAtTime(this.state.volume / 100, 0);
    // create Oscillator nodes
    this.setState({ leftOsc: this.state.audioCtx.createOscillator() }, () => {
      this.setState(
        { rightOsc: this.state.audioCtx.createOscillator() },
        () => {
          this.setState(
            { merger: this.state.audioCtx.createChannelMerger(2) },
            () => {
              this.state.leftOsc.frequency.setValueAtTime(this.state.leftHz, 0);
              this.state.rightOsc.frequency.setValueAtTime(
                this.state.rightHz,
                0
              );
              this.state.leftOsc.connect(this.state.merger, 0, 0);
              this.state.rightOsc.connect(this.state.merger, 0, 1);
              // this.state.merger
              //   .connect(this.state.gainNode)
              //   .connect(this.state.audioCtx.destination);
              /**
               * Changed below for Safari browser
               */
              this.state.merger.connect(this.state.gainNode);
              this.state.gainNode.connect(this.state.audioCtx.destination);
              this.state.leftOsc.start();
              this.state.rightOsc.start();
              this.state.noSleep.enable();
            }
          );
        }
      );
    });
  };
  //Original playIsochronic
  playIsochronic = () => {
    this.state.gainNode.gain.setValueAtTime(this.state.volume / 100 / 2, 0); // overall gain for audio graph
    this.setState({ LFO: this.state.audioCtx.createOscillator() }, () => {//LFO connect to 
      this.setState({ leftOsc: this.state.audioCtx.createOscillator() }, () => {
        this.setState({ lfoGain: this.state.audioCtx.createGain() }, () => {
          this.state.leftOsc.frequency.setValueAtTime(this.state.baseHz, 0); // leftOsc is base frequency
          this.state.LFO.frequency.setValueAtTime(this.state.binVal, 0); // LFO set to 'binary' fequency
          this.state.LFO.connect(this.state.lfoGain.gain);
          // this.state.leftOsc
          //   .connect(this.state.lfoGain)
          //   .connect(this.state.gainNode)
          //   .connect(this.state.audioCtx.destination);
          /**
           * This Block was reformulated below to accomodate Safari behavior
           */
          this.state.leftOsc.connect(this.state.lfoGain);
          this.state.lfoGain.connect(this.state.gainNode);
          this.state.gainNode.connect(this.state.audioCtx.destination);
          this.state.LFO.start(0);
          this.state.leftOsc.start(0);
          this.state.noSleep.enable();
        });
      });
    });
  };

  // Sandbox for playIsochronic
  // playIsochronic = () => {
  //   this.state.gainNode.gain.setValueAtTime(this.state.volume / 100 / 2, 0); // overall gain for audio graph
  //   this.setState({ LFO: this.state.audioCtx.createOscillator() }, () => {// 'bin' Hz 
  //     this.setState({ leftOsc: this.state.audioCtx.createOscillator() }, () => {// base Hz
  //       this.setState({ lfoGain: this.state.audioCtx.createGain() }, () => {
  //         this.setState({ biquadFilter: this.state.audioCtx.createBiquadFilter() }, () => {
  //           this.state.leftOsc.frequency.setValueAtTime(this.state.baseHz, 0); // leftOsc is base frequency
  //           this.state.LFO.frequency.setValueAtTime(this.state.binVal, 0); // LFO set to 'binary' fequency
  //           this.state.biquadFilter.gain.setValueAtTime(this.state.intensity / 100, 0);
  //           // this.state.lfoGain.gain.setValueAtTime(this.state.intensity / 100, 0);
  //           console.log("intensity *** " + this.state.intensity);
  //           console.log("baseHz *** " + this.state.baseHz);
  //           console.log("binVal *** " + this.state.binVal);
  //           ///
  //           this.state.LFO.connect(this.state.biquadFilter);// connect gain node value to LFO
  //           this.state.biquadFilter.connect(this.state.lfoGain.gain);
  //           this.state.leftOsc.connect(this.state.lfoGain); //          
  //           this.state.gainNode.connect(this.state.audioCtx.destination);
  //           this.state.LFO.start(0);
  //           this.state.leftOsc.start(0);
  //           this.state.noSleep.enable();
  //         });
  //       });
  //     });
  //   });
  // };

  stop = () => {
    this.setState({ isPlaying: false }, () => {
      if (this.state.leftOsc) {
        this.state.leftOsc.stop();
      }
      if (this.state.rightOsc) {
        this.state.rightOsc.stop();
      }
      if (this.state.isoOsc) {
        this.state.isoOsc.stop();
      }
      if (this.state.LFO) {
        this.state.LFO.stop();
      }
      this.setState({ leftOsc: null }, () => {
        this.setState({ rightOsc: null }, () => {
          this.setState({ isoOsc: null }, () => {
            this.setState({ LFO: null }, () => {
              this.setState({ btnTxt: "START" });
            });
          });
        });
      });
      this.state.noSleep.disable();
    });
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => {
      this.stop();
      if (this.state.tabValue === 0) {
        this.setState({ volume: 30 }, () => {
          this.state.gainNode.gain.setValueAtTime(this.state.volume / 100, 0);
        });
      } else if (this.state.tabValue === 1) {
        this.setState({ volume: 20 }, () => {
          this.state.gainNode.gain.setValueAtTime(this.state.volume / 100, 0);
        });
      }
    });
  };

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  }

  setPage = (page) => {
    this.setState({ page: page });
  }

  onPresetSelect = (preset) => {
    const presets = JSON.parse(localStorage.getItem('presets'));
    const pre = presets.filter(item => item.name === preset);
    this.setState({ preset: pre }, () => {
      this.setState({ baseHz: parseFloat(pre[0].baseHz) }, () => {
        this.setState({ binHz: parseFloat(pre[0].binHz) }, () => {
          this.setState({ mode: pre[0].mode }, () => {
            this.setState({ page: "controls" }, () => {
              this.handleBinHzChange(this.state.binHz, false);
              this.handleBaseHzChange(this.state.baseHz, false);
              this.handleModeChange(this.state.mode, false);
            });
          })
        })
      })
      // this.setState({ binHz: parseFloat(pre[0].binHz) })
      // console.log("state: " + this.state.baseHz + "/" + this.state.binHz);
      // this.setState({ page: "controls" }, () => {
      // this.setState({ baseHz: parseFloat(pre[0].baseHz) })
      // this.setState({ binHz: parseFloat(pre[0].binHz) })
    });
  }

  render() {
    return (
      <MuiThemeProvider theme={theme} >
        <div>
          <AppBar position="static">
            <Toolbar className="toolbar">
              <MenuIcon
                edge="end"
                className="menuButton"
                color="inherit"
                aria-label="menu"
                onClick={this.toggleMenu}
              >
              </MenuIcon>
              <img src={bee2} className="logo" alt="Binaural Bee logo" />
              <div>
                <Typography className="title">Binaural Bee</Typography>
              </div>
              {/* <Button color="inherit">Login</Button> */}
              <img src={bee2} className="logo" alt="Binaural Bee logo" />
            </Toolbar>
          </AppBar>
          {this.state.showMenu && (
            <Sidebar setPage={this.setPage} toggleMenu={this.toggleMenu} />)}
          {this.state.page === 'presets' && (
            <Presets presets={this.state.presets} onPresetSelect={this.onPresetSelect} />)}
          {this.state.page === 'about' && (
            <About />)}
          {this.state.page === 'howto' && (
            <HowTo />)}
          {this.state.page === 'controls' && (
            <BinauralControls
              handleBaseHzChange={this.handleBaseHzChange}
              handleBinHzChange={this.handleBinHzChange}
              handleIntensityChange={this.handleIntensityChange}
              binVal={this.state.binVal}
              play={this.play}
              // play={this.playTest}
              stop={this.stop}
              handleVolumeChange={this.handleVolumeChange}
              toggle={this.toggle}
              btnTxt={this.state.btnTxt}
              handleModeChange={this.handleModeChange}
              isPlaying={this.state.isPlaying}
              page={this.state.page}
              preset={this.state.preset}
              baseHz={this.state.baseHz}
              binHz={this.state.binHz}
              mode={this.state.mode}
            />)}
          {this.state.page === 'disclaimer' && (<Disclaimer />)}
        </div>
        {/* </div > */}
      </MuiThemeProvider>
    );
  }
}

export default App;
